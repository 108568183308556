import React from 'react';
import GooglePlay from './google-play';
import AppStore from './app-store';

export default function DownloadLinks () {
  return (
    <div style={style}>
      <a
        style={link}
        alt="Get it on Google Play"
        href="https://play.google.com/store/apps/details?id=io.sixth.timelines&utm_source=timelines-home&utm_campaign=timelines-home&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
      <GooglePlay />
      </a>
      <a
        style={link}
        alt="Get it on App Store"
        href="https://apps.apple.com/us/app/timelines-manage-timezones/id1470986230?ls=1"
      >
        <AppStore />
      </a>
    </div>
  )
}

const style = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  minHeight: 210
}

const link = {
  boxShadow: 'none'
}
